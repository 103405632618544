import { handleAPIError, handleResponseMessage } from './utils';
import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error): void => {
        const errorMessage = handleAPIError(error);
        if (errorMessage) toast.error(errorMessage);
        else toast.error(error?.message)
      }
    }),
    mutationCache: new MutationCache({
      onSuccess: (data): void => {
        toast.dismiss();
        const successMessage = handleResponseMessage(data);
        if (successMessage) toast.success(successMessage);
      },
      onError: (error): void => {
        toast.dismiss();
        const errorMessage = handleAPIError(error);
        if (errorMessage) toast.error(errorMessage);
        else toast.error(error?.message)
      }
    })
});

export default queryClient;