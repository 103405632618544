import { type FC } from 'react';
import classes from './Pagination.module.scss';
import classNames from 'classnames';

type PaginationProps = {
    postsPerPage: number;
    totalPosts: number;
    paginate: (param: number) => void;
    currentPage: number;
}

const Pagination: FC<PaginationProps> = ({ postsPerPage, totalPosts, currentPage, paginate }) => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const visiblePages = 5; // Number of visible pages before showing dots
    const pageNumbers: number[] = [];
    const enhancedPageNumbers: number[] = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Calculate start and end page numbers to display
    let start = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let end = Math.min(totalPages, start + visiblePages - 1);

    // Adjust start and end if they are out of bounds
    if (start > 1 && end === totalPages) {
        start = Math.max(1, totalPages - visiblePages + 1);
    }

    enhancedPageNumbers.push(...pageNumbers.slice(start - 1, end));

    return (
        <nav>
            <ul className={classes.pagination}>
                {currentPage > visiblePages && (
                    <>
                        <li className={classes.pagination__item}>
                            <button 
                                onClick={() => paginate(1)} 
                                className={classNames(classes.pagination__link, { [classes['pagination__link--active']]: currentPage === 1 })}
                            >
                                1
                            </button>
                        </li>
                        <p>............</p>
                    </>
                )}

                {enhancedPageNumbers.map(number => (
                    <li key={number} className={classes.pagination__item}>
                        <button 
                            onClick={() => paginate(number)} 
                            className={classNames(classes.pagination__link, { [classes['pagination__link--active']]: number === currentPage })}
                        >
                            {number}
                        </button>
                    </li>
                ))}

                {end < totalPages && (
                    <>
                        <p>............</p>
                        <li className={classes.pagination__item}>
                            <button 
                                onClick={() => paginate(totalPages)} 
                                className={classNames(classes.pagination__link, { [classes['pagination__link--active']]: currentPage === totalPages })}
                            >
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
}

export default Pagination;