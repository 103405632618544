import { api } from "./dataService";
import { HTTP_METHODS } from 'constant';

// FETCH ALL USERS

export const fetchUsers = async () => {
    const res = await api('/api/v1/users');

    return res;
}

// FETCH USER

export const fetchUserDetails = async (accountId: string) => {
    const res = await api(`/api/v1/users/${accountId}`);

    return res;
}

// UPDATE USER

export const updateUser = async (accountId: string, data: any) => {
    const res = await api(`/api/v1/users/${accountId}`, {
        method: HTTP_METHODS.PATCH,
        data: JSON.stringify({ data })
    });

    return res;
}


// DELETE ACCOUNT

export const deleteUser = async (accountId: string) => {
    const res = await api(`/api/v1/users/${accountId}`, {
        method: HTTP_METHODS.DELETE
    })

    return res;
}

// FETCH USER

export const changeUserPassword = async (accountId: string, password: string) => {
    const res = await api(`/api/v1/users/${accountId}/password`, {
        method: HTTP_METHODS.PATCH,
        data: JSON.stringify({ password })
    });

    return res;
}

// CREATE EXPERIMENTS

export const createExperiment = async (accountId: string, config: { name: string, isAvailable: boolean }) => {
    const res = await api(`/api/v1/users/${accountId}/experiments`, {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ name: config.name, isAvailable: config.isAvailable })
    });

    return res;
}

// DELETE EXPERIMENTS

export const deleteExperiment = async (accountId: string, experimentId: string) => {
    const res = await api(`/api/v1/users/${accountId}/experiments/${experimentId}`, {
        method: HTTP_METHODS.DELETE
    });

    return res;
}