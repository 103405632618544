import { type FC } from "react";
import classes from './Header.module.scss';
import classNames from "classnames";

interface BrandTypes {
    [key: string]: { src: string; name: string; phone: string; }
}

const brands: BrandTypes = {
    mhiy: {
        src: '/images/myhomeisyours-full-logo.png',
        name: 'MyHomeIsYours',
        phone: '0330 043 4566'
    },
    icab: {
        src: '/images/ICAB_web_logo.png',
        name: 'ICAB',
        phone: '020 8421 8427'
    }
}

const Header: FC<{ type: string | null; }> = ({ type }) => {
    if (!type) return;

    return (
        <div className={ classes.header }>
            <div className={ classes.header__container }>
                <div className={ classNames(classes.header__logo, {[classes['header__logo--icab']]: type === 'icab'}) }>
                    <img src={ brands[type].src } alt={ brands[type].name } />
                </div>
                <div className={ classes.header__info }>
                    <a href={ `tel:${brands[type].phone}` }> { brands[type].phone } </a>
                </div>
            </div>
        </div>
    )
}

export default Header;