import { type FC, type ReactNode } from "react";
import classes from './AuthLayout.module.scss';

import packageJson from '../../package.json';

type AuthLayoutTypes = {
    children: ReactNode;
}

const currentVersion = packageJson.version;

const AuthLayout: FC<AuthLayoutTypes> = ({ children }) => {
    return (
        <div className={ classes.layout }>
            <div className={ classes.layout__background }>
                <div className={ classes.layout__lines }>
                    <h1 className={ classes.layout__heading }>MyHomeIsYours</h1>
                    <p className={ classes.layout__text }>
                        Manage listings, connect with clients, and close deals faster
                    </p>
                </div>
                <p className={ classes.layout__version }> v{ currentVersion } </p>
            </div>
            <div className={ classes.layout__content }>
                { children }
            </div>
        </div>
    )
}

export default AuthLayout;