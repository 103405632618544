import { Suspense, useState, useEffect} from "react";
import { currentModal, CurrentModal } from "./currentModal";

import classes from './ModalRenderer.module.scss';

import { CloseButton } from "components/shared";

export const ModalRenderer = () => {
  const [modal, updateCurrentModal] = useState<CurrentModal<any> | null>(null);
  
  useEffect(() => currentModal.subscribe(updateCurrentModal), []);
  
  if (modal) {
    const Modal = currentModal.get(modal.name);

    return (
      <Suspense>
          <div className={ classes.modal }>
            <div className={ classes.modal__container }>
              <CloseButton onClick={ () => { currentModal.set(null) } } />
              <Modal {...modal?.props} />
            </div>
          </div>
      </Suspense>
    )
  }
  
  return null;
}