import { type FC } from "react";
import classes from './NoResources.module.scss';

const NoResources: FC<{ title: string; text: string; }> = ({ title, text }) => {
    return (
        <div className={ classes.box }>
            <div className={ classes.box__container }>
                <div className={ classes.box__image }>
                    <img src="/images/empty-box.png" alt="No Resources" />
                </div>
                <h1 className={ classes.box__heading }> { title } </h1>
                <p className={ classes.box__text }> { text } </p>
            </div>
        </div>
    )
}

export default NoResources;