import { type ReactNode, type ComponentPropsWithoutRef, type FC } from "react";
import classes from './Button.module.scss';

import Spinner from "../spinner/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

type ButtonType = {
    mode: string;
    size?: string;
    isLoading?: boolean;
    children: ReactNode;
    icon?: IconDefinition;
} & ComponentPropsWithoutRef<'button'>;

const Button: FC<ButtonType> = ({ children, isLoading, mode, icon, size, ...props }) => {
    return (
        <button className={ classNames(classes[`btn-${mode}`], { [classes[`btn-${mode}--${size}`]]: size }) } {...props} disabled={ isLoading || props.disabled }>
            { icon && <FontAwesomeIcon icon={ icon } /> }
            { isLoading ? <Spinner /> : children }
        </button>
    )
}

export default Button;