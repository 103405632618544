import { type FC, type ReactNode } from "react";
import classes from './Component.module.scss';

import classNames from "classnames";

const Component: FC<{ 
    children: ReactNode; 
    className: string;
    includePadding?: boolean; 
}> = ({ children, className, includePadding = true }) => {
    const componentClass = classNames(classes.component, { [classes['component--padding']]: includePadding }, className);
    
    return (
        <div className={ componentClass }>
            { children }
        </div>
    )
}

export default Component;