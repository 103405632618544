import { items } from "utils/permissions";

export const myAccountNavItems = {
    home: {
        name: 'Dashboard',
        path: '',
        icon: { 
            type: 'material-symbol',
            path: 'space_dashboard' 
        },
        end: true,
        permissions: items.navigation.HOME,
        experiment: false
    },
    listings: {
        name: 'Listings',
        path: 'properties',
        icon: {
            type: 'material-symbol',
            path: 'home_work' 
        },
        end: false,
        permissions: items.navigation.PROPERTIES,
        experiment: false,
        submenus: {
            create: {
                name: 'Create',
                path: '/dashboard/properties/create',
                end: false,
                permissions: items.navigation.ENQUIRIES
            },
            list: {
                name: 'List',
                path: '/dashboard/properties/list',
                end: false,
                permissions: items.navigation.ENQUIRIES
            }
        }
    },
    enquiries: {
        name: 'Enquiries',
        path: 'enquiries',
        icon: {
            type: 'material-symbol',
            path: 'list' 
        },
        end: false,
        permissions: items.navigation.ENQUIRIES,
        experiment: 'Enquiries',
        submenus: {
            create: {
                name: 'Create',
                path: '/dashboard/enquiries/create',
                end: false,
                permissions: items.navigation.ENQUIRIES
            },
            list: {
                name: 'List',
                path: '/dashboard/enquiries/list',
                end: false,
                permissions: items.navigation.ENQUIRIES
            }
        }
    },
    bookings: {
        name: 'Bookings',
        path: 'bookings',
        icon: {
            type: 'material-symbol',
            path: 'calendar_month' 
        },
        end: false,
        permissions: items.navigation.BOOKINGS,
        experiment: false,
    },
    clients: {
        name: 'Clients',
        path: 'clients',
        icon: { 
            type: 'material-symbol',
            path: 'folder_supervised' 
        },
        end: true,
        permissions: items.navigation.CLIENTS,
        experiment: false
    },
    tracker: {
        name: 'Tracker',
        path: 'tracker',
        icon: { 
            type: 'material-symbol',
            path: 'trending_up' 
        },
        end: true,
        permissions: items.navigation.TRACKER,
        experiment: false
    },
    agents: {
        name: 'Agents',
        path: 'agents',
        icon: {
            type: 'material-symbol',
            path: 'real_estate_agent' 
        },
        end: false,
        permissions: items.navigation.AGENTS,
        experiment: 'Enquiries',
        submenus: {
            create: {
                name: 'Create',
                path: '/dashboard/agents/create',
                end: false,
                permissions: items.navigation.AGENTS
            },
            list: {
                name: 'List',
                path: '/dashboard/agents/list',
                end: false,
                permissions: items.navigation.AGENTS
            }
        }
    },
    companies: {
        name: 'Companies',
        path: 'companies',
        icon: {
            type: 'material-symbol',
            path: 'corporate_fare' 
        },
        end: false,
        permissions: items.navigation.COMPANIES,
        experiment: false,
        submenus: {
            create: {
                name: 'Create',
                path: '/dashboard/companies/create',
                end: false,
                permissions: items.navigation.COMPANIES
            },
            list: {
                name: 'List',
                path: '/dashboard/companies/list',
                end: false,
                permissions: items.navigation.COMPANIES
            }
        }
    },
    accounts: {
        name: 'Accounts',
        path: 'accounts',
        icon: {
            type: 'material-symbol',
            path: 'manage_accounts' 
        },
        end: false,
        permissions: items.navigation.ACCOUNTS,
        experiment: false
    },
    settings: {
        name: 'Settings',
        path: 'settings',
        icon: {
            type: 'material-symbol',
            path: 'settings' 
        },
        end: false,
        permissions: items.navigation.ENQUIRIES,
        experiment: 'GlobalSettings'
    }
}