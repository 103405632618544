import { lazy, type FC } from "react";

const View: FC = lazy(() => import('views/account/View'));
const Accounts: FC = lazy(() => import('views/account/accounts/Accounts'));
const AccountDetails: FC = lazy(() => import('views/account/accounts/AccountDetails'));

const Listings: FC = lazy(() => import('views/account/properties/list/Listings'));
const Property: FC = lazy(() => import('views/account/properties/details/Property'));
const CreateProperty: FC = lazy(() => import('views/account/properties/create/CreateProperty'));
const CreatedPropertyScreen: FC<{ property: any; onChangeStep: () => void }> = lazy(() => import('views/account/properties/create/CreatedPropertyScreen'));

const Settings: FC = lazy(() => import('views/account/global/Settings'));

const Enquiries: FC<{ tags: string[] }>  = lazy(() => import('views/account/enquiries/Enquiries'));
const Enquiry: FC  = lazy(() => import('views/account/enquiries/details/Enquiry'));
const CreateEnquiry: FC = lazy(() => import('views/account/enquiries/Step1-Create'));

const Companies: FC  = lazy(() => import('views/account/companies/Companies')); 
const CreateCompany: FC = lazy(() => import('views/account/companies/create/CreateCompany')); 
const CompanyDetails: FC = lazy(() => import('views/account/companies/details/Company'));

const Clients: FC = lazy(() => import('views/account/clients/Clients')); 
const ClientDetails: FC = lazy(() => import('views/account/clients/details/Client'));

const CreateAgent: FC = lazy(() => import('views/account/agents/CreateAgent'));
const Agents: FC = lazy(() => import('views/account/agents/Agents')); 

const Bookings: FC = lazy(() => import('views/account/bookings/Bookings')); 

const Tracker: FC = lazy(() => import('views/account/tracker/Tracker')); 

export default {
    home: { index: true, exact: true, component: View, path: '' },
    accounts: { index: false, component: Accounts, path: 'accounts' },
    accountDetails: { index: false, component: AccountDetails, path: 'accounts/:accountId' },
    listings: { index: false, component: Listings, path: 'properties/list' },
    property: { index: false, component: Property, path: 'properties/list/:propertyId' },
    createProperty: { index: false, component: CreateProperty, path: 'properties/create' },
    createdPropertyScreen: { index: false, component: CreatedPropertyScreen, path: 'properties/success' },
    settings: { index: false, component: Settings, path: 'settings' },
    clients: { index: false, component: Clients, path: 'clients' },
    clientDetails: { index: false, component: ClientDetails, path: 'clients/:clientId' },
    enquiries: { index: false, component: Enquiries, path: 'enquiries/list' },
    createEnquiry: { index: false, component: CreateEnquiry, path: 'enquiries/create' },
    enquiryDetails: { index: false, component: Enquiry, path: 'enquiries/list/:enquiryId' },
    bookings: { index: false, component: Bookings , path: 'bookings' },
    companies: { index: false, component: Companies, path: 'companies/list' },
    createCompany: { index: false, component: CreateCompany, path: 'companies/create' },
    companyDetails: { index: false, component: CompanyDetails, path: 'companies/list/:companyId' },
    agents: { index: false, component: Agents, path: 'agents/list' },
    createAgent: { index: false, component: CreateAgent, path: 'agents/create' },
    tracker: { index: false, component: Tracker, path: 'tracker' }
}