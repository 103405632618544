import { type FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import classes from './SignIn.module.scss';

import { AuthLayout } from "layout";
import { Input, Button, Checkbox, Component } from 'components/shared';

import { handleAPIError, validateEmail } from "utils";
import { signIn } from "service/auth.service";

import { useForm, Controller, SubmitHandler } from "react-hook-form"

import { useAuthContext } from "context/AuthContext";

interface IFormInputs {
    email: string;
    password: string;
    rememberMe: boolean;
}

const SignIn: FC = () => {
    const { handleSubmit, control, watch, formState: { errors } } = useForm<IFormInputs>({
        defaultValues: {
          email: '',
          password: '',
          rememberMe: false
        },
    })  

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [searchParams] = useSearchParams();
    const comingFrom = searchParams.get('from');
    
    const { onLogin } = useAuthContext();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
        setIsLoading(true);

        try {
            const res = await signIn(data);
            onLogin(res?.data);
            
            if (comingFrom) navigate(comingFrom);
            else navigate('/dashboard');
        } catch (error) {
            const message = handleAPIError(error, true);
            if (message) {
                setError(message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <AuthLayout> 
            <Component className={ classes.auth__component }>
                <div className={ classes.auth__indication }>
                    <h1 className={ classes.auth__heading }>Welcome Back</h1>
                    <p className={ classes.auth__text }>Enter your email and password to access your account</p>
                </div>
                <form className={ classes.auth__form } onSubmit={ handleSubmit(onSubmit) }>
                    { error && <p className={ classes.auth__error }> { error } </p> }
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: 'Email is required', pattern: validateEmail }}
                        render={({ field }) => <Input label="Email" {...field} errors={ errors } />}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'Password is required' }}
                        render={({ field }) => <Input type="password" label="Password" {...field} errors={ errors } />}
                    />
                    <div>
                        <Controller name="rememberMe" control={ control } render={({ field }) => (
                            <Checkbox id="rememberMe" checked={ watch("rememberMe") } label="Remember Me" {...field} />
                        )} />
                    </div>
                    <div className={ classes.auth__submit }>
                        <Button type="submit" mode="dark" size="full" isLoading={ isLoading }>Sign In</Button>
                    </div>
                </form>
                <div className={ classes.auth__disclaimer }>
                    <Link to="/recover-password">Forgot your password?</Link>
                </div>
            </Component>
        </AuthLayout>
    )
}

export default SignIn;