import { type FC } from "react";
import classes from './Loader.module.scss';

const Loader: FC<{ displayText?: boolean }> = ({ displayText = true }) => {
    return (
        <div className={ classes['cw-loading-wrap'] }>
            <div className={ classes['cw-loading'] }>
                <div></div>
            </div>
            { displayText && <p className={ classes['cw-loading-text'] }>Loading...</p> }
        </div>
    )
}

export default Loader;