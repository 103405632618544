import { lazy, type LazyExoticComponent } from 'react';

export enum Modals {
    CreateAccountModal,
    DeleteAccountModal,
    AssignEnquiryModal,
    DeleteEnquiryModal
}

export const modalsConfig: Record<Modals, LazyExoticComponent<any>> = {
    [Modals.CreateAccountModal]: lazy(() => import('../CreateAccountModal')),
    [Modals.DeleteAccountModal]: lazy(() => import('../DeleteAccountModal')),
    [Modals.AssignEnquiryModal]: lazy(() => import('../AssignEnquiryModal')),
    [Modals.DeleteEnquiryModal]: lazy(() => import('../DeleteEnquiryModal'))
}