import { type FC, Fragment, useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import classes from './Breadcrumbs.module.scss';

import { lArray } from "utils";
import { myAccountNavItems } from "constant";
import classNames from "classnames";

const Breadcrumbs: FC = () => {
    const [element, setElement] = useState<any>(null);
    const [subElement, setSubelement] = useState<any>(null);
    
    const location = useLocation();
    const params = useParams();

    const findItem = () => {
        let subItem;

        const element: any = lArray(myAccountNavItems).find((item: any) => {
            return item.path && location.pathname.includes(item.path);
        })

        if (element && element.submenus) {
            subItem = lArray(element.submenus).find((item: any) => {
                return location.pathname.includes(item.path);
            })
        }

        if (element) setElement(element);
        else setElement(null);

        if (subItem) setSubelement(subItem);
        else setSubelement(null);
    }

    const includesParams = () => {
       return Boolean(subElement && location.pathname !== subElement.path && Object.values(params).length > 0);
    }

    useEffect(() => {
        findItem();
    }, [location])

    if (location && (location.pathname === '/dashboard' || location.pathname === '/dashboard/')) {
        return null;
    }

    return (
        <div className={ classes.nav }>
            <div className={ classes.nav__container }>
                <div className={ classes.nav__header }>
                    { element && <p className={ classes.nav__heading }> { element.name } </p> }
                    <ul className={ classes.nav__breadcrumbs }>
                        <li className={ classes.nav__item }>
                            <NavLink to="/dashboard">Menu</NavLink>
                        </li>
                        <li className={ classes.nav__circle }></li>
                        { element && (
                            <li className={ classNames(classes.nav__item, { [classes['nav__item--disabled']]: !subElement }) }>
                                <NavLink to='/dashboard'> { element.name } </NavLink>
                            </li>
                        ) }
                        { subElement && (
                            <Fragment>
                                <li className={ classes.nav__circle }></li>
                                <li className={ classNames(classes.nav__item, { [classes['nav__item--disabled']]: !includesParams() }) }>
                                    <NavLink to={ subElement.path }> { subElement.name } </NavLink>
                                </li>
                            </Fragment>
                        ) }
                        { includesParams() && (
                            <Fragment>
                                <li className={ classes.nav__circle }></li>
                                <li className={ classNames(classes.nav__item, classes['nav__item--disabled']) }> 
                                    <NavLink to={ location.pathname }>
                                        { Object.values(params)[0] }
                                    </NavLink>
                                </li>
                            </Fragment>
                        ) }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumbs;