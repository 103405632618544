import React, { useState, useEffect, type ReactNode } from 'react';

import type { CompanyProps, Subject } from 'types/shared';
import { fetchCurrentAccount } from 'service/account.service';
import { fetchUsers } from 'service/user.service';
import { fetchCompanies } from 'service/company.service';

import { handleAPIError } from 'utils';

import { useAuthContext } from './AuthContext';
import { Loader } from 'components/shared';

type ContextTypes = {
    users: any;
    companies: CompanyProps[];
    currentUser: Subject | null;
    fetchCurrentUser: () => void
}

type CurrentUserContextTypes = {
    value?: ContextTypes;
    children: ReactNode;
}

export const CurrentUserContext = React.createContext<ContextTypes>({
    users: null,
    companies: [],
    currentUser: null,
    fetchCurrentUser: () => {}
});

export const CurrentUserProvider = (props: CurrentUserContextTypes) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<Subject | null>(null);
    const [users, setUsers] = useState<any>(null);
    const [companies, setCompanies] = useState<CompanyProps[]>([]);

    const { token } = useAuthContext();

    const allDataLoaded = () => {
        return (
            currentUser &&
            users &&
            (companies && companies.length > 0)
        ) ? true : false;
    }

    const fetchCurrentUser = async () => {
        setIsLoading(true);

        try {
            const response = await Promise.allSettled([
                fetchCurrentAccount(),
                fetchUsers(),
                fetchCompanies()
            ]);
            
            if (response && response[0].status === "fulfilled") {
                setCurrentUser(response[0].value.data.userData);
            }

            if (response && response[1].status === "fulfilled") {
                setUsers(response[1].value.data.users);
            }

            if (response && response[2].status === "fulfilled") {
                setCompanies(response[2].value.data.companies);
            }
        } catch (error) {
            handleAPIError(error, true);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => { 
        if (token && token !== undefined && token !== '') {
            fetchCurrentUser();
        }
    }, [token]);

    const value = { 
        users,
        companies,
        currentUser, 
        fetchCurrentUser
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <CurrentUserContext.Provider value={ value }>
            { allDataLoaded() ? props.children : null }
        </CurrentUserContext.Provider>
    )
}

export const useCurrentUser = () => React.useContext(CurrentUserContext);