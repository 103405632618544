type AddressItem = { long_name: string; short_name: string; types: string[]; };

const findAddressItem = (array: {address_components: AddressItem[]}, string: string) => {
    return array.address_components.find((item: AddressItem) => {
        return item.types.includes(string);
    })?.long_name;
}
  
export const parseAddress = (searchArray: any) => {
    const street = findAddressItem(searchArray, 'route');
    const city = findAddressItem(searchArray, 'postal_town');
    const zip = findAddressItem(searchArray, 'postal_code');
    const state = findAddressItem(searchArray, 'country');
    const formattedAddress = searchArray.formatted_address;
  
    const lat = searchArray.geometry.location.lat;
    const lon = searchArray.geometry.location.lng;
  
    return {
        street,
        city,
        state,
        zip,
        freeFormAddress: formattedAddress,
        position: {
            type: 'Point',
            coordinates: [lon, lat]
        }
    }
}