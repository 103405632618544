import React, { type ReactNode } from 'react';

import { fetchGlobalSettings } from 'service/global.service';

import { Loader } from 'components/shared';

import { useQuery } from '@tanstack/react-query';

type ContextTypes = {
    globalSettings: any
}

type CurrentUserContextTypes = {
    value?: ContextTypes;
    children: ReactNode;
}

export const GlobalSettingsContext = React.createContext<ContextTypes>({
    globalSettings: null
});

export const GlobalSettingsProvider = (props: CurrentUserContextTypes) => {
    const { data: response } = useQuery({
        queryKey: ['settings'],
        queryFn: fetchGlobalSettings
    })

    const value = { 
        globalSettings: response?.data.settings
    };

    return (
        <GlobalSettingsContext.Provider value={ value }>
            { response?.data.settings ? props.children : <Loader /> }
        </GlobalSettingsContext.Provider>
    )
}

export const useGlobalSettings = () => React.useContext(GlobalSettingsContext);