import { api } from "./dataService";
import { HTTP_METHODS } from 'constant';

// POST

export const createCompany = async (formData: FormData) => {
    return await api('/api/companies', {
        method: HTTP_METHODS.POST,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

// FETCH

export const fetchCompanies = async () => {
    return await api('/api/companies');
}

// FETCH BY ID

export const fetchCompanyById = async (companyId: string) => {
    return await api(`/api/companies/${companyId}`);
}

// UPDATE

export const updateCompany = async (config: {companyId: string, formData: FormData}) => {
    return await api(`/api/companies/${config.companyId}`, {
        method: HTTP_METHODS.PATCH,
        data: config.formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

// DELETE

export const deleteCompany = async (companyId: string) => {
    return await api(`/api/companies/${companyId}`, {
        method: HTTP_METHODS.DELETE
    })
}