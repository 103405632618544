import { FC } from "react";
import classes from './ErrorFallback.module.scss';

const ErrorFallback: FC = () => {
    return (
        <div className={ classes.error }>
            <div className={ classes.error__container }>
                <h1 className={ classes.error__heading }>Something went wrong</h1>
                <p className={ classes.error__text }>It seems like we’ve run into an issue. Don’t worry, this could be a temporary glitch.</p>
                <a href="/dashboard" className={ classes.error__btn }>Home</a>
            </div>
        </div>
    )
}

export default ErrorFallback;