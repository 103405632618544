import { api } from "./dataService";
import { HTTP_METHODS } from 'constant';

// FETCH CURRENT USER

export const fetchCurrentAccount = async () => {
    const res = await api('/account/fetch');

    return res;
}

// CHANGE PROFILE PICTURE

export const changeProfilePicutre = async (accountId: string, formData: FormData) => {
    return await api(`/account/${accountId}/image`, {
        method: HTTP_METHODS.PATCH,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

// REFRESH TOKEN

export const getNewAccessToken = async (refreshToken: string) => {
    const res = await api('/api/auth/refresh', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ refreshToken: refreshToken })
    })

    return res;
}

// SEND RESET EMAIL

export const sendResetEmail = async (email: string) => {
    const res = await api('/api/auth/send-reset-email', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ email: email })
    })

    return res;
}

// RESET PASSWORD

export const resetPassword = async (password: string, resetToken: string) => {
    const res = await api('/api/auth/reset-password', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ password: password, resetToken: resetToken })
    })

    return res;
}

// LOG OUT

export const logOutCurrentAccount = async () => {
    const res = await api('/api/auth/logout', {
        method: HTTP_METHODS.POST
    })

    return res;
}