export const items = {
    navigation: {
        HOME: 'HOME',
        CLIENTS: 'CLIENTS',
        ACCOUNTS: 'ACCOUNTS',
        PROPERTIES: 'PROPERTIES',
        BOOKINGS: 'BOOKINGS',
        ENQUIRIES: 'ENQUIRIES',
        COMPANIES: 'COMPANIES',
        AGENTS: 'AGENTS',
        TRACKER: 'TRACKER'
    },
    actions: {
        ACCOUNT_DATA: 'ACCOUNT_DATA',
        DELETE_PROPERTY: 'DELETE_PROPERTY',
        ASSIGN_ENQUIRY: 'ASSIGN_ENQUIRY',
        DELETE_ASSIGNEE: 'DELETE_ASSIGNEE'
    },
    tabs: {
        NOT_ASSIGNED: 'NOT_ASSIGNED',
        YOUR_ENQUIRIES: 'YOUR_ENQUIRIES',
        OFFERED_OUT: 'OFFERED_OUT',
        OVERVIEW: 'OVERVIEW',
        ASSIGNED: 'ASSIGNED',
        AWAITING_RESPONSE: 'AWAITING_RESPONSE'
    }
}

export const roles = {
    USER: 'user',
    MODERATOR: 'moderator',
    ADMIN: 'admin',
    MASTERADMIN: 'masteradmin'
}

const mapping = new Map();

mapping.set(items.navigation.HOME, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.CLIENTS, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.AGENTS, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.PROPERTIES, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.BOOKINGS, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.ACCOUNTS, [roles.MASTERADMIN]);
mapping.set(items.navigation.ENQUIRIES, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.COMPANIES, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.navigation.TRACKER, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);

mapping.set(items.actions.DELETE_PROPERTY, [roles.MASTERADMIN]);
mapping.set(items.actions.ACCOUNT_DATA, [roles.MASTERADMIN]);
mapping.set(items.actions.ASSIGN_ENQUIRY, [roles.MASTERADMIN, roles.ADMIN]);
mapping.set(items.actions.DELETE_ASSIGNEE, [roles.MASTERADMIN, roles.ADMIN]);

mapping.set(items.tabs.OVERVIEW, [roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.tabs.NOT_ASSIGNED, [roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.tabs.ASSIGNED, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.tabs.AWAITING_RESPONSE, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.tabs.OFFERED_OUT, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);
mapping.set(items.tabs.YOUR_ENQUIRIES, [roles.USER, roles.MODERATOR, roles.ADMIN, roles.MASTERADMIN]);

export const hasPermission = (file: string, action: string) => {
    if (!file) {
        return false;
    }

    if (mapping.has(action)) {
        return mapping.get(action).includes(file);
    }

    return false;
}